import { ETrackEvent } from 'enums/ETrackEvent'
import { interWbSession } from 'inter-webview-bridge'

interface ProductTag {
  product: string
}

export const handleTagHeader = async ({ product }: ProductTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_HEADER, {
      ref_figma: '12',
      flow: ETrackEvent.F_CANCELATION,
      screen: ETrackEvent.S_CANCEL_CHOICES,
      action_id: 'back',
      product,
    })
    .catch(window.console.log)
}

export const handleTagRefund = async ({ product }: ProductTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_CENTRAL_SUBSCRIPTION_FLOW, {
      ref_figma: '4',
      flow: ETrackEvent.F_CANCELATION,
      screen: ETrackEvent.S_CANCEL_CHOICES,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'cancelar e receber reembolso',
      product,
    })
    .catch(window.console.log)
}

export const handleTagRenew = async ({ product }: ProductTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_CENTRAL_SUBSCRIPTION_FLOW, {
      ref_figma: '5',
      flow: ETrackEvent.F_CANCELATION,
      screen: ETrackEvent.S_CANCEL_CHOICES,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'cancelar a renovação automática',
      product,
    })
    .catch(window.console.log)
}

export const handleTagExpired = async ({ product }: ProductTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_CENTRAL_SUBSCRIPTION_FLOW, {
      ref_figma: '6',
      flow: ETrackEvent.F_CANCELATION,
      screen: ETrackEvent.S_CANCEL_CHOICES,
      ref_type: 'message',
      content_action: 'dado carregado',
      action_id: 'prazo de cancelamento excedido',
      product,
    })
    .catch(window.console.log)
}
