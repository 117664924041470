import React from 'react'

import { BottomSheet as BS } from 'react-spring-bottom-sheet'

import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'

import { Content, Footer, Header } from './BottomSheet.styles'

interface BottomSheetProps {
  showBottomSheet: boolean
  headerLabel?: string
  headerBtLabel?: string
  headerBtHandler?: () => void
  footerBtLabel?: string
  footerBtDisable?: boolean
  footerBtLoading?: boolean
  footerBtHandler?: () => void
  bsContent: React.ReactNode
  headerSubtitle?: string
}

export const BottomSheet = ({
  showBottomSheet,
  headerLabel,
  headerBtLabel,
  headerBtHandler,
  footerBtLabel,
  footerBtDisable,
  footerBtLoading,
  footerBtHandler,
  bsContent,
  headerSubtitle,
}: BottomSheetProps) => (
  <BS
    open={showBottomSheet}
    header={
      <Header>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Text color="typography" variant="headline-h3" semiBold>
            {headerLabel}
          </Text>

          {headerSubtitle && (
            <Text style={{ marginTop: '4px' }} color="typography" variant="caption-1" bold>
              {headerSubtitle}
            </Text>
          )}
        </div>
        <Button className="close-bt" variant="link" onClick={headerBtHandler}>
          <Text variant="body-3" color="primary" bold colorWeight={500}>
            {headerBtLabel}
          </Text>
        </Button>
      </Header>
    }
    footer={
      <Footer>
        {footerBtLabel && (
          <Button
            disabled={footerBtDisable}
            onClick={footerBtHandler}
            className="submit-bt"
            isLoading={footerBtLoading}
          >
            {footerBtLabel}
          </Button>
        )}
      </Footer>
    }
  >
    <Content>{bsContent}</Content>
  </BS>
)
