import React from 'react'

import './App.css'

import { ErrorBoundary } from 'react-error-boundary'
import Error from 'components/Error'
import AppBar from 'components/AppBar'
import { ApiErrors } from 'types/http/ApiError'
import ChooseCancel from 'pages/Cancellation/ChooseCancel/ChooseCancel'
import ConfirmCancel from 'pages/Cancellation/ConfirmCancel/ConfirmCancel'
import ConcludeCancel from 'pages/Cancellation/ConcludeCancel/ConcludeCancel'

import { Router } from '@reach/router'

const Routes: React.FC = () => {
  const ErrorFallback = () => (
    <>
      <AppBar name="Cancelamento" goBackNative showHomeAction />
      <Error goBackNative />
    </>
  )

  const myErrorHandler = (error: Error, info: { componentStack: string }) => {
    const err = error as ApiErrors
    err.message = `${err.message} | ${info.componentStack}`
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
      <Router className="container-routers">
        <ChooseCancel path="/" />
        <ConfirmCancel path="/confirm-cancel" />
        <ConcludeCancel path="/conclude-cancel" />
      </Router>
    </ErrorBoundary>
  )
}

export default Routes
