import React from 'react'

import useGoBack from 'hooks/useGoBack'
import { interWbNavigate } from 'inter-webview-bridge'

import PaperIcon from '@inter/icons/orangeds/LD/historico-de-pedidos'
import HomeIcon from '@inter/icons/orangeds/LG/home'
import LeftArrowIcon from '@inter/icons/orangeds/XL/arrow-left'
import { useNavigate } from '@reach/router'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'

import { AppBarStyles } from './AppBar.styles'

interface AppBarProps {
  name?: string
  subscriber?: boolean
  showHomeAction?: boolean
  goBackNative?: boolean
  onClickSubscriber?: () => void
  bankingCustomer?: boolean
  removeGoBack?: boolean
  backTag?: () => void
  homeTag?: () => void
}

const AppBar = ({
  subscriber,
  name,
  showHomeAction,
  removeGoBack,
  goBackNative,
  onClickSubscriber = () => undefined,
  bankingCustomer,
  backTag = () => undefined,
  homeTag = () => undefined,
}: AppBarProps) => {
  const navigate = useNavigate()
  const goBack = useGoBack({ goBackNative })

  return (
    <AppBarStyles>
      {!removeGoBack ? (
        <Button
          icon={<LeftArrowIcon width={24} height={24} color="var(--primary500)" />}
          variant="link"
          onClick={async () => {
            backTag()
            goBack()
          }}
          className="iconStyle"
          style={{ padding: '12px 0' }}
        />
      ) : (
        <Button variant="link" className="iconStyle" style={{ width: '12px' }} />
      )}

      <Text
        color="typography"
        colorWeight={500}
        variant="headline-h3"
        className="appbar-title"
        semiBold
        style={{
          whiteSpace: 'nowrap',
          position: 'relative',
          left: !showHomeAction && !subscriber ? -15 : 0,
        }}
      >
        {name}
      </Text>
      {subscriber && (
        <Button
          icon={
            <PaperIcon width={30} height={30} color="var(--primary500)" className="iconStyle" />
          }
          variant="link"
          onClick={() => {
            homeTag()
            navigate('/menu', { state: { bankingCustomer } })
            onClickSubscriber()
          }}
          style={{ padding: '12px 0 ' }}
        />
      )}
      {showHomeAction && (
        <Button
          icon={<HomeIcon name="home" width={24} height={24} color="var(--primary500)" />}
          variant="link"
          onClick={() => interWbNavigate.requestGoBack()}
          style={{ padding: '12px 0 ' }}
        />
      )}
      {!showHomeAction && !subscriber && <span />}
    </AppBarStyles>
  )
}

export default AppBar
