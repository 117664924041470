import styled from 'styled-components'

export const AppBarStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 24px;
  position: fixed;
  background: var(--neutral-theme);
  z-index: 2;
  top: 0;

  .iconStyle {
    position: relative;
    right: 1rem;
  }

  .appbar-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 10px;
  }
`
