import styled from 'styled-components'

export const ReasonItem = styled.div`
  margin-bottom: 8.5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`
export const DarkModeWrapper = styled.div<{ isDarkMode: boolean }>`
  img {
    filter: ${(props) =>
      props.isDarkMode
        ? 'invert(100%) sepia(0%) saturate(7458%) hue-rotate(104deg) brightness(105%) contrast(101%)'
        : 'invert(0%) sepia(6%) saturate(4%) hue-rotate(21deg) brightness(101%) contrast(100%)'};
  }
`

export const UpperContentWrapper = styled.div`
  text-align: center;
`
export const LowerContentWrapper = styled.div``

export const TextWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const BottomSheetWrapper = styled.div`
  padding-top: 1rem;
`
