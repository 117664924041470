import { ETrackEvent } from 'enums/ETrackEvent'
import { interWbSession } from 'inter-webview-bridge'

interface ProductTag {
  product: string
}

interface BSCancelTag {
  product: string
  cancellation_option: string
}

export const handleTagHeader = async ({ product }: ProductTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_HEADER, {
      ref_figma: '12',
      flow: ETrackEvent.F_CANCELATION,
      screen: ETrackEvent.S_CANCEL_CONFIRM,
      action_id: 'back',
      product,
    })
    .catch(window.console.log)
}

export const handleTagSave = async ({ product }: ProductTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_CENTRAL_SUBSCRIPTION_FLOW, {
      ref_figma: '2',
      flow: ETrackEvent.F_CANCELATION,
      screen: ETrackEvent.S_CANCEL_CONFIRM,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'continuar economizando',
      product,
    })
    .catch(window.console.log)
}

export const handleTagCancel = async ({ product }: ProductTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_CENTRAL_SUBSCRIPTION_FLOW, {
      ref_figma: '3',
      flow: ETrackEvent.F_CANCELATION,
      screen: ETrackEvent.S_CANCEL_CONFIRM,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'cancelar',
      product,
    })
    .catch(window.console.log)
}

export const handleTagSelectionBS = async ({ product, cancellation_option }: BSCancelTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_CENTRAL_SUBSCRIPTION_FLOW, {
      ref_figma: '7',
      flow: ETrackEvent.F_CANCELATION,
      screen: ETrackEvent.S_CANCEL_REASON,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'motivo de cancelamento',
      cancellation_option,
      product,
    })
    .catch(window.console.log)
}

export const handleTagCloseBS = async ({ product }: ProductTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_CENTRAL_SUBSCRIPTION_FLOW, {
      ref_figma: '13',
      flow: ETrackEvent.F_CANCELATION,
      screen: ETrackEvent.S_CANCEL_REASON,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'fechar',
      product,
    })
    .catch(window.console.log)
}

export const handleTagRefund = async ({ product }: ProductTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_CENTRAL_SUBSCRIPTION_FLOW, {
      ref_figma: '14',
      flow: ETrackEvent.F_CANCELATION,
      screen: ETrackEvent.S_CANCEL_CONFIRM,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'cancelar',
      product,
    })
    .catch(window.console.log)
}
