import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 0 24px;
  padding-top: 64px;
  height: 100%;
`

export const AlertTheme = styled.div`
  > div > div {
    padding: 0px;
  }

  .textIcon {
    display: flex;
    align-items: flex-start;
  }
`

export const ShimmerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 24px;
  padding-top: 64px;
`

export const IconWrapper = styled.div`
  width: 16px;
  margin-right: 16px;
`
