import React from 'react'

import ChevronRightIcon from '@inter/icons/orangeds/XL/chevron-right'
import { Text } from '@interco/inter-ui/components/Text'

import { IOption } from '../../pages/Cancellation/CancellationTypes'
import * as S from './Option.styles'

const Option = ({ keyName, texts, canReversal, handleOption }: IOption) => (
  <S.OptionItem
    onClick={() => handleOption({ key: keyName, texts })}
    className={keyName === 'cancel_reversal' && !canReversal ? 'option-disabled' : ''}
  >
    <S.TextWrapper>
      <Text
        color="typography"
        variant="body-3"
        bold
        style={{ fontWeight: 700, color: 'var(--gray500)', width: '100%', marginBottom: '4px' }}
      >
        {texts.title}
      </Text>
      <Text
        color="typography"
        variant="caption-1"
        style={{ fontWeight: 400, color: 'var(--gray400)', width: '100%' }}
      >
        {texts.subtitle}
      </Text>
    </S.TextWrapper>

    <S.IconWrapper>
      <ChevronRightIcon
        width={24}
        height={24}
        color={
          keyName === 'cancel_reversal' && !canReversal ? 'var(--gray300)' : 'var(--primary500)'
        }
      />
    </S.IconWrapper>
  </S.OptionItem>
)

export default Option
