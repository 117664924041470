import React, { useEffect, useState } from 'react'

import AppBar from 'components/AppBar'
import useAsync from 'hooks/UseAsync'
import Option from 'components/Option/Option'
import Error from 'components/Error'

import { navigate, RouteComponentProps } from '@reach/router'
import { Text } from '@interco/inter-ui/components/Text'
import { Alert } from '@interco/inter-ui/components/Alert'
import { Skeleton } from '@interco/inter-ui/components/Skeleton'

import { ICancellation, IOptionProps } from '../CancellationTypes'
import * as S from './ChooseCancel.styles'
import * as T from './ChooseCancelTags'

const ChooseCancel = (_: RouteComponentProps) => {
  const [cancellationPage, getCancellationPage] = useAsync<ICancellation>('get', undefined)
  const [subscriptionId, setSubscriptionId] = useState('')

  const fetchCancellationPage = async (subscriptionIdentification: string) => {
    getCancellationPage(
      `${process.env.REACT_APP_API}/subscriptions/${subscriptionIdentification}/cancel/options`,
    )
  }

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    setSubscriptionId(params.subscriptionId)

    fetchCancellationPage(params.subscriptionId)
  }, [])

  const alertAnalitics = async () => {
    if (
      cancellationPage?.data?.options?.alert &&
      cancellationPage?.data?.options?.alert?.includes('fora do prazo')
    ) {
      T.handleTagExpired({ product: cancellationPage?.data?.product })
    }
  }

  useEffect(() => {
    alertAnalitics()
  }, [cancellationPage])

  const handleOption = async (option: IOptionProps) => {
    if (!cancellationPage.data.options.canReversal && option.key === 'cancel_reversal') {
      return
    }

    if (option.key === 'cancel') {
      T.handleTagRenew({ product: cancellationPage?.data?.product })
    } else {
      T.handleTagRefund({ product: cancellationPage?.data?.product })
    }

    navigate('/confirm-cancel', {
      state: {
        confirm:
          option.key === 'cancel'
            ? cancellationPage.data.confirm.cancel
            : cancellationPage.data.confirm.cancel_reversal,
        cancellation: cancellationPage.data.cancellation,
        option,
        subscriptionId,
        product: cancellationPage?.data?.product,
      },
    })
  }

  if (cancellationPage.isPending) {
    return (
      <>
        <AppBar
          name="Cancelamento"
          backTag={() => T.handleTagHeader({ product: cancellationPage?.data?.product })}
        />
        <S.ShimmerWrapper>
          <Skeleton width="100%" height="50px" style={{ marginBottom: '24px' }} />
          <Skeleton width="100%" height="100px" style={{ marginBottom: '24px' }} />
          <Skeleton width="100%" height="100px" />
        </S.ShimmerWrapper>
      </>
    )
  }

  if (cancellationPage.isError) {
    return (
      <>
        <AppBar name="Cancelamento" />
        <Error />
      </>
    )
  }

  return (
    <>
      <AppBar
        name="Cancelamento"
        backTag={() => T.handleTagHeader({ product: cancellationPage?.data?.product })}
      />

      <S.Container>
        <>
          <Text
            color="typography"
            variant="headline-h2"
            style={
              cancellationPage?.data?.options?.subtitle
                ? { marginBottom: '8px', fontWeight: 700, width: '100%', display: 'block' }
                : { marginBottom: '24px', fontWeight: 700, width: '100%', display: 'block' }
            }
          >
            {cancellationPage?.data?.options?.title}
          </Text>

          {cancellationPage?.data?.options?.subtitle && (
            <Text
              color="typography"
              variant="caption-1"
              style={{ marginBottom: '24px', width: '100%', display: 'block' }}
            >
              <div dangerouslySetInnerHTML={{ __html: cancellationPage.data.options.subtitle }} />
            </Text>
          )}

          {cancellationPage?.data?.options?.options.map((option) => (
            <Option
              key={option.key}
              handleOption={() => handleOption(option)}
              keyName={option.key}
              texts={option.texts}
              canReversal={cancellationPage.data?.options?.canReversal}
            />
          ))}

          {cancellationPage?.data?.options?.alert && (
            <S.AlertTheme>
              <Alert type="grayscale">
                <div className="textIcon">
                  <Text color="typography" variant="caption-1">
                    {cancellationPage.data.options?.alert}
                  </Text>
                </div>
              </Alert>
            </S.AlertTheme>
          )}
        </>
      </S.Container>
    </>
  )
}

export default ChooseCancel
