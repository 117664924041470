import { ETrackEvent } from 'enums/ETrackEvent'
import { interWbSession } from 'inter-webview-bridge'

interface ProductTag {
  product: string
}

interface SuccessTag {
  product: string
  action_id: string
}

interface HeaderTag {
  product: string
  screen: string
}

export const handleTagHeader = async ({ product, screen }: HeaderTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_HEADER, {
      ref_figma: '12',
      flow: ETrackEvent.F_CANCELATION,
      screen,
      action_id: 'back',
      product,
    })
    .catch(window.console.log)
}

export const handleTagSubscription = async ({ product }: ProductTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_CENTRAL_SUBSCRIPTION_FLOW, {
      ref_figma: '7',
      flow: ETrackEvent.F_CANCELATION,
      screen: ETrackEvent.S_CANCEL_ERROR,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'ir para minha assinatura',
      product,
    })
    .catch(window.console.log)
}

export const handleTagHome = async ({ product }: ProductTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_CENTRAL_SUBSCRIPTION_FLOW, {
      ref_figma: '8',
      flow: ETrackEvent.F_CANCELATION,
      screen: ETrackEvent.S_CANCEL_CONFIRMED,
      ref_type: 'botão',
      content_action: 'toque',
      action_id: 'voltar pra home',
      product,
    })
    .catch(window.console.log)
}

export const handleTagLoadSuccess = async ({ product, action_id }: SuccessTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_CENTRAL_SUBSCRIPTION_SUCCESS, {
      ref_figma: '9',
      flow: ETrackEvent.F_CANCELATION,
      screen: ETrackEvent.S_CANCEL_CONFIRMED,
      content_action: 'dado carregado',
      action_id,

      forma_pag: 'NA',
      price: 'NA',
      value: 'NA',
      product,
    })
    .catch(window.console.log)
}

export const handleTagLoad = async ({ product }: ProductTag) => {
  await interWbSession
    .requestAnalytics(ETrackEvent.T_CENTRAL_SUBSCRIPTION_FLOW, {
      ref_figma: '11',
      flow: ETrackEvent.F_CANCELATION,
      screen: ETrackEvent.S_CANCEL_ERROR,
      content_action: 'dado carregado',
      action_id: 'ocorreu um erro no cancelamento',
      product,
    })
    .catch(window.console.log)
}
