import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 0 24px;
  padding-top: 64px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
`
