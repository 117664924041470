import styled from 'styled-components'

export const OptionItem = styled.div`
  border: 1px solid var(--gray200);
  border-radius: 8px;
  padding: 16px 12px;
  margin-bottom: 24px;
  display: flex;

  &.option-disabled {
    pointer-events: none;
  }

  &.option-disabled > div > svg {
    stroke: var(--gray300);
  }

  &.option-disabled > div > span {
    color: var(--gray300) !important;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`
