import React, { useEffect, useState } from 'react'

import AppBar from 'components/AppBar'
import { BottomSheet } from 'components/BottomSheet'
import Divider from 'components/Divider'
import { interWbNavigate } from 'inter-webview-bridge'
import { EEnvironment } from 'enums/EEnvironment'

import { navigate, RouteComponentProps } from '@reach/router'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { interWbSession } from '@interco/inter-webview-bridge'

import { Container } from '../Cancellation.styles'
import { IConfirmCancelProps } from '../CancellationTypes'
import * as S from './ConfirmCancel.styles'
import * as T from './ConfirmCancelTags'

const ConfirmCancel = ({ location }: RouteComponentProps) => {
  const [isDarkMode, setIsDarkMode] = useState(false)
  const [showBottomsheet, setShowBottomsheet] = useState(false)
  const confirmInfo = location?.state as IConfirmCancelProps

  const handleGoHome = async () => {
    T.handleTagSave({ product: confirmInfo.product })
    await interWbNavigate.openDeepLink(confirmInfo.confirm.buttons[0].deepLink)
  }

  const handleCancel = async (reasonId: string, reasonText: string) => {
    T.handleTagSelectionBS({ product: confirmInfo.product, cancellation_option: reasonText })
    navigate('/conclude-cancel', {
      state: {
        subscriptionId: confirmInfo.subscriptionId,
        option: confirmInfo.option,
        reasonId,
        deepLink: confirmInfo.confirm.buttons[0].deepLink,
        product: confirmInfo.product,
      },
    })
  }

  const handleCloseBottomsheet = async () => {
    T.handleTagCloseBS({ product: confirmInfo.product })
    setShowBottomsheet(false)
  }

  const getThemeInfo = async () => {
    const appInfo = await interWbSession.getAppInfo()
    setIsDarkMode(appInfo?.isDarkMode)
  }

  useEffect(() => {
    getThemeInfo()
  }, [isDarkMode])

  return (
    <>
      <AppBar
        name="Cancelamento"
        backTag={() => T.handleTagHeader({ product: confirmInfo.product })}
        removeGoBack={false}
      />

      <Container>
        <S.UpperContentWrapper style={{ textAlign: 'center' }}>
          <img
            style={{ width: '64px', marginTop: '72px', marginBottom: '24px' }}
            src={confirmInfo.confirm.imageUrl}
            alt="Ilustração de confirmação de cancelamento"
          />

          <S.TextWrapper>
            <Text
              color="typography"
              variant="headline-h1"
              style={{ marginBottom: '16px', fontWeight: 700 }}
            >
              {confirmInfo.confirm.title}
            </Text>
            <Text color="typography" variant="body-3">
              {confirmInfo.confirm.subtitle}
            </Text>
          </S.TextWrapper>
        </S.UpperContentWrapper>

        <S.LowerContentWrapper>
          <Button
            variant="secondary"
            style={{ width: '100%', marginBottom: '24px' }}
            onClick={async () => {
              if (confirmInfo.option.key === 'cancel') {
                T.handleTagCancel({ product: confirmInfo.product })
              } else {
                T.handleTagRefund({ product: confirmInfo.product })
              }
              setShowBottomsheet(true)
            }}
          >
            {confirmInfo.confirm.buttons[1].text}
          </Button>
          <Button
            style={{ width: '100%', marginBottom: '24px' }}
            variant="primary"
            onClick={() => handleGoHome()}
          >
            {confirmInfo.confirm.buttons[0].text}
          </Button>
        </S.LowerContentWrapper>

        <BottomSheet
          showBottomSheet={showBottomsheet}
          headerBtLabel="Fechar"
          headerBtHandler={handleCloseBottomsheet}
          headerLabel={confirmInfo.cancellation.title}
          bsContent={
            <>
              <Text
                color="typography"
                variant="caption-1"
                style={{ display: 'flex', flexWrap: 'wrap', margin: '20px 0 30px' }}
              >
                {confirmInfo.cancellation.subtitle}
              </Text>

              {confirmInfo.cancellation.reasons.map((reason) => (
                <S.ReasonItem key={reason.id} onClick={() => handleCancel(reason.id, reason.text)}>
                  <S.DarkModeWrapper isDarkMode={isDarkMode}>
                    <img
                      src={`${
                        process.env.REACT_APP_ENV === EEnvironment.PRODUCTION
                          ? 'https://marketplace.bancointer.com.br/'
                          : 'https://cdnmarketplace.uatbi.com.br/'
                      }centralassinatura/images/icons/${reason.iconUrl}.svg`}
                      alt="Icone do texto"
                      style={{ marginRight: '16px' }}
                    />
                  </S.DarkModeWrapper>
                  <Text color="typography" variant="caption-1">
                    {reason.text}
                  </Text>
                  <Divider
                    borderWidth={2}
                    color="var(--gray100)"
                    style={{ marginTop: '8px', width: '100%' }}
                  />
                </S.ReasonItem>
              ))}
            </>
          }
        />
      </Container>
    </>
  )
}

export default ConfirmCancel
