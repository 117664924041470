import * as React from 'react'

import axios from 'axios'
import { interWbHttp, IWbHttpData, IWbHttpOptions } from 'inter-webview-bridge'
import useSafeDispatch from 'hooks/useSafeDispatch'
import { handleError, isStatus2xx, isStatus4xx, WbHttpError } from 'utils/httpUtils'
import { ApiErrors, BridgeError } from 'types/http/ApiError'

export type THttpMethod = 'get' | 'post' | 'put' | 'delete'

export interface UseAsyncResponse<T> {
  isIdle: boolean
  isPending: boolean
  isError: boolean
  isSuccess: boolean
  status: EAsyncStatus
  data: T
  error?: ApiErrors<T>
  setData: (data: T) => void
  setError: (error: ApiErrors<T>) => void
}

enum EAsyncStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}

export type TState<T = unknown> = {
  status: EAsyncStatus
  data: T
  error?: ApiErrors<T>
}

type TAsyncReducer<T> = React.Reducer<TState<T>, Partial<TState<T>>>

const initialState: TState = {
  /**
   * The data key is explicitly declared to avoid the use of optional chaining
   * every time we access it.
   */
  data: undefined,
  status: EAsyncStatus.IDLE,
}

function reducer(state: TState, update: Partial<TState>): TState {
  return { ...state, ...update }
}

type TAsyncRun<T> = (
  url: string,
  dataToSend?: Partial<T>,
  headers?: Record<string, string>,
) => Promise<T>

/**
 * useAsync hook
 *
 * A hook to perform a asyncronous request.
 *
 * @param httpMethod - The HTTP method of the request
 *
 * @returns
 *
 * The "run" function, the resolved value, the request status, and some
 * utilities like "isSuccess", "isError" "setData", "setError", etc.
 *
 * @example
 *
 * import { useAsync } from './hooks'
 *
 * const Products = () => {
 *   const getProducts = useAsync('get')
 *
 *   React.useEffect(() => {
 *     getProducts.run(`api.com/product`)
 *   }, [getProducts.run])
 *
 *   if (getProducts.isSuccess) {
 *     return <>{produts.data.map(product => <div>Product {product.name}</div>)}</>
 *   }
 *   if (getProducts.isPending) { return <Loading /> }
 *   if (getProducts.isError) { return <Error /> }
 *   return null
 * }
 */
const useAsync = <T>(
  httpMethod: THttpMethod,
  options?: IWbHttpOptions,
): [UseAsyncResponse<T>, TAsyncRun<T>] => {
  const [{ data, status, error }, unsafeSetState] = React.useReducer<TAsyncReducer<T>>(
    reducer as TAsyncReducer<T>,
    initialState as TState<T>,
  )
  const setState = useSafeDispatch(unsafeSetState)

  const setData = React.useCallback(
    (state: T) => setState({ data: state, status: EAsyncStatus.SUCCESS }),
    [setState],
  )

  const setError = React.useCallback(
    (err: ApiErrors<T>): void => setState({ error: err, status: EAsyncStatus.ERROR }),
    [setState],
  )

  const run = React.useCallback(
    process.env.REACT_APP_AXIOS === 'true' ? GetCallback1 : GetCallback,
    [httpMethod, setData, options?.timeout],
  )

  return [
    {
      isIdle: status === EAsyncStatus.IDLE,
      isPending: status === EAsyncStatus.PENDING,
      isError: status === EAsyncStatus.ERROR,
      isSuccess: status === EAsyncStatus.SUCCESS,
      status: status as EAsyncStatus,
      data,
      error,
      setData,
      setError,
    },
    run,
  ]

  async function GetCallback1<A>(
    url: string,
    dataToSend?: Partial<T | A>,
    headers?: Record<string, string>,
  ) {
    setState({ status: EAsyncStatus.PENDING })
    const result = await axios({
      method: httpMethod,
      url,
      data: dataToSend,
      headers,
    })
    if (isStatus2xx(result.status)) {
      window.console.log('responseFormated', result.data)
      setData(result.data)
      return result.data
    }
    setError(new ApiErrors({ message: 'fail' }))
    return Promise.reject(new ApiErrors({ message: 'fail' }))
  }

  async function GetCallback<A>(
    url: string,
    dataToSend?: Partial<T | A>,
    headers?: Record<string, string>,
  ) {
    setState({ status: EAsyncStatus.PENDING })
    try {
      let responseHttp = {} as IWbHttpData
      if (httpMethod === 'get' || httpMethod === 'delete') {
        responseHttp = await interWbHttp[httpMethod](url, {
          ...headers,
        })
      } else {
        responseHttp = await interWbHttp[httpMethod](url, dataToSend, { ...headers }, options)
      }
      if (isStatus2xx(responseHttp.httpStatus)) {
        const formatedResponse = JSON.parse(responseHttp.response)
        window.console.log('responseFormated', formatedResponse)
        setData(formatedResponse)
        return formatedResponse
      }
      const formatedResponse = JSON.parse(responseHttp.response) as ApiErrors<unknown>
      const errors = new ApiErrors({
        message: formatedResponse?.message,
        action: formatedResponse?.action,
        errors: formatedResponse?.errors,
        method: httpMethod,
        statusCode: formatedResponse?.statusCode,
      })
      setError(errors)
      return Promise.reject(errors)
    } catch (err) {
      const response =
        typeof err === 'string'
          ? (JSON.parse(err as string) as IWbHttpData & BridgeError)
          : (err as IWbHttpData & BridgeError)
      const formatedResponse = response.response ? JSON.parse(response.response) : response
      if (isStatus4xx(response.httpStatus)) {
        const errors = new ApiErrors({
          message: response?.message,
          action: response?.action,
          errors: formatedResponse?.errors,
          method: httpMethod,
          statusCode: response?.httpStatus,
        })
        setData(formatedResponse)
        setError(errors as ApiErrors<unknown>)
        return Promise.reject(errors)
      }

      const formatedErrors = handleError(err as WbHttpError)

      setError(formatedErrors)

      window.console.log('ERROR', err)
      return Promise.reject(err)
    }
  }
}

export default useAsync
