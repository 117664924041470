import React, { useEffect } from 'react'

import './App.css'
import 'animate.css'
import 'react-spring-bottom-sheet/dist/style.css'

import Routes from 'Routes'
import { GlobalStyle } from 'App.styles'
import { EEnvironment } from 'enums/EEnvironment'
import smoothscroll from 'smoothscroll-polyfill'
import { ApiErrors } from 'types/http/ApiError'
import NewRelic from 'utils/NewRelic'

import InterWebviewBridge, { interWbSession } from '@interco/inter-webview-bridge'
import { initTheme, Theme } from '@interco/inter-ui'

smoothscroll.polyfill()

export const bridgeNative = InterWebviewBridge.getInstance()

const App: React.FC = () => {
  const getThemeInfo = async () => {
    const appInfo = await interWbSession.getAppInfo()
    initTheme(Theme.PF, appInfo?.isDarkMode)
  }

  useEffect(() => {
    try {
      getThemeInfo()
      if (
        process.env.REACT_APP_ENV === EEnvironment.DEVELOPMENT ||
        process.env.REACT_APP_ENV === EEnvironment.STAGING
      ) {
        bridgeNative.setDebug(true)
      }
      interWbSession.editToolbar('', false).catch(window.console.error)
    } catch (error) {
      const err = error as ApiErrors
      NewRelic.noticeError(err)
    }
  }, [])

  return (
    <>
      <GlobalStyle />
      <Routes />
    </>
  )
}

export default App
