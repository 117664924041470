import React, { useEffect } from 'react'

import ImgError from 'assets/imgs/error.svg'
import { AppContainer, SpaceBetween } from 'App.styles'
import useGoBack from 'hooks/useGoBack'
import { interWbSession } from 'inter-webview-bridge'

import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'

interface ErrorProps extends React.HTMLAttributes<unknown> {
  title?: string
  description?: string
  goBackNative?: boolean
  cancellationError?: boolean
}

const Error = ({
  title = 'Ocorreu um erro',
  description = 'Ocorreu um erro inesperado, nossa equipe já está trabalhando para solucionar este problema, por favor tente novamente mais tarde.',
  goBackNative,
  cancellationError,
  ...props
}: ErrorProps) => {
  const goBack = useGoBack({ goBackNative })

  const errorAnalitics = async () => {
    await interWbSession.requestAnalytics('CENTRAL_ASSINATURA_ACESSO', {
      ref_figma: '11',
      screen: 'cancelamento erro',
      content_action: 'dado carregado',
      action_id: 'Ocorreu um erro no cancelamento',
    })
  }

  useEffect(() => {
    if (cancellationError) {
      errorAnalitics()
    }
  }, [])

  return (
    <AppContainer fullHeight {...props}>
      <SpaceBetween>
        <div style={{ marginTop: 70, textAlign: 'center' }}>
          <img src={ImgError} alt="" style={{ marginBottom: 24 }} />
          <Text
            color="typography"
            variant="headline-h1"
            colorWeight={500}
            semiBold
            className="bold d-block"
          >
            {title}
          </Text>
          <Text
            className="d-block"
            color="typography"
            variant="body-3"
            colorWeight={400}
            style={{ marginTop: 8 }}
          >
            {description}
          </Text>
        </div>
        <Button
          variant="primary"
          onClick={async () => {
            await interWbSession.requestAnalytics('CENTRAL_ASSINATURA_FLUXO', {
              ref_figma: '7',
              screen: 'Cancelamento',
              ref_type: 'botao',
              content_action: 'clique',
              action_id: 'Voltar para a home',
            })

            goBack()
          }}
        >
          Voltar
        </Button>
      </SpaceBetween>
    </AppContainer>
  )
}

export default Error
