export enum ETrackEvent {
  // geral
  T_HEADER = 'HEADER',
  T_CENTRAL_SUBSCRIPTION_FLOW = 'CENTRAL_ASSINATURA_FLUXO',
  T_CENTRAL_SUBSCRIPTION_SUCCESS = 'CENTRAL_ASSINATURA_SUCESSO',
  T_CENTRAL_SUBSCRIPTION_ACCESS = 'CENTRAL_ASSINATURA_ACESSO',

  F_CANCELATION = 'cancelamento',
  S_CANCEL_CHOICES = 'formas de cancelamento',
  S_CANCEL_CONFIRM = 'confirmação de cancelamento',
  S_CANCEL_REASON = 'bottomsheet motivo de cancelamento',
  S_CANCEL_CONFIRMED = 'assinatura cancelada',
  S_CANCEL_ERROR = 'cancelamento erro',
}
