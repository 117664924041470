import React, { useEffect } from 'react'

import AppBar from 'components/AppBar'
import useAsync from 'hooks/UseAsync'
import Error from 'components/Error'
import { interWbNavigate } from 'inter-webview-bridge'

import { RouteComponentProps } from '@reach/router'
import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'
import { Skeleton } from '@interco/inter-ui/components/Skeleton'

import { Container } from '../Cancellation.styles'
import * as T from './ConcludeCancelTags'

interface IConcludeCancelProps {
  headerTitle: string
  subtitle: string
  imageUrl: string
  title: string
  reasonId: string
}

interface IConcludeLocationProps {
  subscriptionId: string
  option: {
    key: string
    texts: {
      subtitle: string
      title: string
    }
  }
  reasonId: string
  product: string
}

const ConcludeCancel = ({ location }: RouteComponentProps) => {
  const concludeInfo = location?.state as IConcludeLocationProps
  const [cancellation, putCancellation] = useAsync<IConcludeCancelProps>('put', undefined)

  const fetchCancellation = async () => {
    putCancellation(
      `${process.env.REACT_APP_API}/subscriptions/${concludeInfo.subscriptionId}/cancel/options/${concludeInfo.option.key}`,
      {
        reasonId: concludeInfo.reasonId,
      },
    )
  }

  useEffect(() => {
    fetchCancellation()
  }, [])

  useEffect(() => {
    if (cancellation.isSuccess) {
      T.handleTagLoadSuccess({ product: concludeInfo?.product, action_id: cancellation.data.title })
    }
  }, [cancellation])

  if (cancellation.isPending) {
    return (
      <>
        <AppBar
          removeGoBack
          showHomeAction
          name="Cancelamento"
          backTag={() =>
            T.handleTagHeader({ product: concludeInfo?.product, screen: 'assinatura cancelada' })
          }
        />
        <div style={{ padding: '24px' }}>
          <div style={{ width: '100%' }}>
            <Skeleton width="100%" height="300px" />
            <Skeleton width="100%" height="50px" />
            <Skeleton width="100%" height="150px" />
          </div>

          <div style={{ marginTop: '24px', width: '100%' }}>
            <Skeleton width="100%" height="50px" />
          </div>
        </div>
      </>
    )
  }

  if (cancellation.isError) {
    return (
      <>
        <AppBar
          removeGoBack
          showHomeAction
          name="Cancelamento"
          backTag={() =>
            T.handleTagHeader({ product: concludeInfo?.product, screen: 'cancelamento erro' })
          }
        />
        <Error cancellationError />
      </>
    )
  }

  const handleGoBackHome = async () => {
    T.handleTagHome({ product: concludeInfo?.product })
    interWbNavigate.requestGoBack()
  }

  return (
    <>
      <AppBar
        removeGoBack
        showHomeAction
        name="Cancelamento"
        backTag={() =>
          T.handleTagHeader({ product: concludeInfo?.product, screen: 'assinatura cancelada' })
        }
      />

      <Container>
        <div style={{ textAlign: 'center' }}>
          <img
            style={{ width: '64px', marginTop: '72px', marginBottom: '24px' }}
            src={cancellation?.data?.imageUrl}
            alt="Ilustração de conclusão de cancelamento"
          />

          <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
            <Text
              color="typography"
              variant="headline-h1"
              style={{ width: '100%', marginBottom: '16px', fontWeight: 700 }}
            >
              {cancellation?.data?.title}
            </Text>
            <Text color="typography" variant="body-3">
              <div dangerouslySetInnerHTML={{ __html: cancellation?.data?.subtitle }} />
            </Text>
          </div>
        </div>

        <div>
          <Button style={{ width: '100%', marginBottom: '24px' }} onClick={handleGoBackHome}>
            Voltar para a Home
          </Button>
        </div>
      </Container>
    </>
  )
}

export default ConcludeCancel
